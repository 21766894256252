<template>
    <div class="main">
        <div class="search">
            <el-select size="mini" style="width: 160px" v-model="search.type" placeholder="종류선택" @change="getCusTempList">
                <el-option :value="managerConst.CustomercenterTemplateType.TYPE_NORMAL" label="일반문의 템플렛">일반문의 템플렛</el-option>
                <el-option :value="managerConst.CustomercenterTemplateType.TYPE_BANK_CARD" label="실계좌 템플렛">실계좌 템플렛</el-option>
                <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD" label="가상계좌 1 템플렛">가상계좌 1  템플렛</el-option>
                <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_2" label="가상계좌 2 템플렛">가상계좌 2  템플렛</el-option>
                <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_3" label="가상계좌 3 템플렛">가상계좌 3  템플렛</el-option>
                <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_4" label="가상계좌 4 템플렛">가상계좌 4  템플렛</el-option>
            </el-select>
            <el-input size="mini" style="margin-left: 20px;width: 150px" placeholder="닉네임" v-model="search.searchString"></el-input>
            <el-button size="mini" type="primary" style="margin-left: 30px" @click="getCusTempList">
                검색
            </el-button>

            <el-button size="mini" type="success" style="margin-left: 30px" @click="edit({user:{}},managerConst.MODE_SAVE)">
                템플렛등록
            </el-button>
        </div>
        <div class="data">
            <el-table
                    :data="cusTempList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="종류"
                        width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type === managerConst.CustomercenterTemplateType.TYPE_NORMAL">일반문의 템플렛</span>
                        <span v-if="scope.row.type === managerConst.CustomercenterTemplateType.TYPE_BANK_CARD">실계좌 템플렛</span>
                        <span v-if="scope.row.type === managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD">가상계좌 1 회원정보</span>
                        <span v-if="scope.row.type === managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_2">가상계좌 2 회원정보</span>
                        <span v-if="scope.row.type === managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_3">가상계좌 3 회원정보</span>
                        <span v-if="scope.row.type === managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_4">가상계좌 4 회원정보</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="제목"
                        width="600">
                    <template slot-scope="scope">
                        <span style="display: inline-block;width: 100%;cursor: pointer"  @click="edit(scope.row,managerConst.MODE_EDIT)">{{scope.row.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label=""
                        width="140">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="edit(scope.row,managerConst.MODE_EDIT)">수정</el-button>
                        <el-button size="mini" type="danger" @click="deleteCusTemp(scope.row.id)">삭제</el-button>

                    </template>
                </el-table-column>
            </el-table>

        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20,50, 100, 150]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
            <el-dialog title="문의템플렛 수정/등록" :visible.sync="visiable" style="width: 100%">
                <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap">
                    <div style="width: 50%">
                        <h5>제목</h5>
                        <el-input size="mini" style="width: 80%" placeholder="제목"  v-model="cusTemp.title"></el-input>
                    </div>
                    <div style="width: 50%">
                        <h5>종류선택</h5>
                        <el-select size="mini" style="width: 80%" v-model="cusTemp.type" placeholder="종류선택">
                            <el-option :value="managerConst.CustomercenterTemplateType.TYPE_NORMAL" label="일반문의 템플렛">일반문의 템플렛</el-option>
                            <el-option :value="managerConst.CustomercenterTemplateType.TYPE_BANK_CARD" label="실계좌 템플렛">실계좌 템플렛</el-option>
                            <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD" label="가상계좌 1 템플렛">가상계좌 1  템플렛</el-option>
                            <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_2" label="가상계좌 2 템플렛">가상계좌 2  템플렛</el-option>
                            <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_3" label="가상계좌 3 템플렛">가상계좌 3  템플렛</el-option>
                            <el-option :value="managerConst.CustomercenterTemplateType.TYPE_FACK_BANK_CARD_4" label="가상계좌 4 템플렛">가상계좌 4  템플렛</el-option>
                        </el-select>
                    </div>
                    <div style="width: 50%">
                        <h5>정열순위</h5>
                        <el-input size="mini" style="width: 80%" placeholder="정열순위"  v-model="cusTemp.sort"></el-input>
                    </div>
                    <div style="width: 50%" v-if="mode == managerConst.MODE_SAVE">
                        <h5 style="color: #ff4d4d;font-weight: bold">회원 닉네임(* 가상계좌선택시 필수 입력사항입니다)</h5>
                        <el-input size="mini"
                                  style="width: 80%"
                                  placeholder="회원 닉네임"
                                  v-if="cusTemp.user"
                                  v-model="cusTemp.user.nickname"></el-input>
                    </div>
                    <div class="editor" style="width: 100%;padding: 10px 0">

                        <Toolbar
                                style="border-bottom: 1px solid #ccc"
                                :editor="editor"
                                :defaultConfig="toolbarConfig"
                                :mode="editormode"
                        />
                        <Editor
                                style="height: 300px; overflow-y: hidden;"
                                :value="editorhtml"
                                :defaultConfig="editorConfig"
                                :mode="editormode"
                                @onCreated="onEditorCreated"
                                @onChange="onEditorChange"
                        />

                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="visiable = false">취소</el-button>
                    <el-button type="primary" @click="save">확인</el-button>
                </div>

            </el-dialog>
        </div>
    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    import {
        deleteCusTemp,
        getCusTempList,
        saveCusTemp,
        updateCusTemp
    } from "../../network/manager/customerTemplateRequest";
    import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

    export default {
        name: "ManagerCustomerTemplate",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp, Editor, Toolbar},
        data() {
            return {
                visiable: false,
                activeNames: 0,
                search: {},
                cusTemp: {user:{}},
                cusTempList: [],
                mode: managerConst.MODE_SAVE,
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                editor: null,
                editorhtml: '',
                toolbarConfig: {},
                editorConfig: {placeholder: '내용을입력하세요...'},
                editormode: 'default', // or 'simple'

            }
        },
        methods: {
            edit(tmp, mode) {
                this.visiable = true
                this.mode = mode
                this.cusTemp = tmp;
                this.editorhtml = tmp.reply
            },
            getCusTempList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getCusTempList(this.search,this.pageNum,this.pageSize).then(res => {
                    this.cusTempList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            save() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (this.mode == managerConst.MODE_SAVE) {
                    saveCusTemp(this.cusTemp).then(res => {
                        loadingInstance.close()
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '등록이 완료되였습니다'
                            });
                            this.getCusTempList()
                            this.visiable = false
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                } else {
                    updateCusTemp(this.cusTemp).then(res => {
                        loadingInstance.close()
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                            this.getCusTempList()
                            this.visiable = false
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }

            },
            deleteCusTemp(id) {
                this.$confirm('삭제 하시겠습니까?', '삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    deleteCusTemp(id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getCusTempList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getCusTempList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCusTempList();
            },
            onEditorCreated(editor) {
                this.editor = Object.seal(editor)
            },
            onEditorChange(editor) {
                this.cusTemp.reply = editor.getHtml()
            },
        },
        created() {
            this.search.type = managerConst.CustomercenterTemplateType.TYPE_NORMAL
            this.getCusTempList()

        },
        beforeDestroy() {
            const editor = this.editor
            if (editor == null) return
            editor.destroy()
        },
        watch: {}
    }
</script>

<style scoped>

</style>